import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { APP_ID } from '@angular/core';

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
    providers: [{ provide: APP_ID, useValue: 'workstory.io' }],
  })
  .catch((err) => console.error(err));
